import React from "react"

import StyleGuide from "../components/StyleGuide"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const Styleguide = () => (
  <Layout
    backgroundColor={"white"}
    navColor={"white"}
    bannerBG={false}
    bannerBgColor="transparent"
  >
    <SEO
      title="Thesis | Brand guidelines"
      keywords={[
        "style guide",
        "brand guide",
        "thesis agency",
        "portland agency",
        "marketing agency",
      ]}
      description="Welcome to your Thesis brand style guide: Everything you need to write, design, walk, and/or talk on behalf of Thesis, a digital agency in Portland, OR. Last updated June 1, 2020."
    />
    <StyleGuide />
  </Layout>
)

export default Styleguide
