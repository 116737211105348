import React, { Component } from "react"
import { Link } from "gatsby"

import styles from "./styles.module.less"

export default class StyleGuide extends Component {
  render() {
    return (
      <div>
        <div id="home" className={styles.thesisbrandguidelineshome}>
          <div className={styles.p1}>
            <div className={styles.background}>
              <div className={styles.header_blocks}>
                <div className={styles.our_style}>
                  <div>
                    <div className={styles.header}>Our style.</div>
                    <div className={styles.your}>
                      Your guide to <span>making</span> on behalf of Thesis.
                    </div>
                  </div>

                  <Link className={styles.more} to="/styleguide/style">
                    <svg
                      preserveAspectRatio="none"
                      viewBox="187.80001831054688 873 17 16"
                      className={styles.arrow}
                    >
                      <path d="M 196.8000030517578 873 L 204.8000030517578 881 L 196.8000030517578 889 L 195.4000091552734 887.5999755859375 L 201.0000152587891 882 L 187.8000183105469 882 L 187.8000183105469 880 L 201 880 L 195.3999938964844 874.4000244140625 L 196.8000030517578 873 Z" />
                    </svg>
                  </Link>
                </div>
                <div className={styles.our_story}>
                  <div>
                    <div className={styles.header}>Our story.</div>
                    <div className={styles.your}>
                      Your guide to <span>talking</span> on behalf of Thesis.
                    </div>
                  </div>

                  <Link className={styles.more} to="/styleguide/story">
                    <svg
                      preserveAspectRatio="none"
                      viewBox="187.80001831054688 873 16.99993896484375 16"
                      className={styles.arrow}
                    >
                      <path d="M 196.8000030517578 873 L 204.8000030517578 881 L 196.8000030517578 889 L 195.4000091552734 887.5999755859375 L 201.0000152587891 882 L 187.8000183105469 882 L 187.8000183105469 880 L 201 880 L 195.3999938964844 874.4000244140625 L 196.8000030517578 873 Z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
